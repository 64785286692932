import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

// Components
import ParseContent from 'components/shared/ParseContent'
import CustomLink from 'components/shared/CustomLink'

// Elements
import TitleDefault from 'components/elements/TitleDefault'

// SVG
import ArrowRight from 'img/arrow-right.svg'

const PackageContainer = styled.div`
  max-width: 1230px !important;
`

const PackagesWrapper = styled(motion.div)``

const Package = styled(CustomLink)`
  height: 210px;
  width: 100%;

  &:hover {
    .read-more:after {
      padding-left: 20px;
    }
  }

  @media (max-width: 575px) {
    width: 95%;
    height: 205px;
  }
`

const PackageWrapper = styled(motion.div)`
  & div {
    background-color: ${(props) => props.theme.color.face.mainLight};
  }

  &:nth-child(2) {
    & div {
      background-color: #f2eee7;
    }
  }

  &:nth-child(3) {
    & div {
      background-color: ${(props) => props.theme.color.face.main};
    }
  }

  &:nth-child(4) {
    & div {
      background-color: ${(props) => props.theme.color.face.mainDark};
    }
  }

  &:nth-child(5) {
    & div {
      background-color: ${(props) => props.theme.color.face.secondary};
    }
  }
`

const InnerPackageWrapper = styled.div`
  height: 60%;
`

const Content = styled(ParseContent)`
  height: 30%;

  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    font-size: ${(props) => props.theme.font.size['16']};
  }
  & p {
    margin-bottom: 0.3rem;
  }
`

const ReadMore = styled.span`
  height: 50%;

  &:after {
    transition: all 0.2s ease-in-out;
    content: url(${ArrowRight});
    padding-left: ${(props) => props.theme.font.spacing.l};
  }
`

interface PackagesProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Packages
  location?: any
}

const Packages: React.FC<PackagesProps> = ({ fields }) => {
  const [currentIndex, setCurrentIndex] = React.useState<number | null>(null)

  return (
    <section className="py-5">
      <PackageContainer className="container">
        <TitleDefault>{fields.description}</TitleDefault>
        <PackagesWrapper className="row justify-content-between">
          {fields?.package?.map((edge, index: number) => {
            const isHover = index === currentIndex

            return (
              <PackageWrapper
                className="col-xl col-lg-3 col-md-4 col-sm-6 d-flex align-items-center justify-content-center mb-5"
                whileHover={{ scale: 1.1 }}
                onMouseEnter={() => setCurrentIndex(index)}
                onMouseLeave={() => setCurrentIndex(null)}
                whileTap={{ scale: 0.9 }}
              >
                <Package to={edge?.link?.url || ''}>
                  <div className="h-100 d-flex justify-content-center align-items-center">
                    <InnerPackageWrapper className="px-2 d-flex flex-column justify-content-between">
                      {/* eslint-disable-next-line react/no-array-index-key */}
                      <ParseContent content={edge?.title} key={index} />
                      <div style={{ height: 50 }}>
                        <Content
                          content={edge?.description}
                          className={`${isHover ? 'd-lg-flex' : 'd-lg-none '}`} // Default show on mobile, on Desktop hover necessary
                        />
                      </div>
                      <ReadMore className="read-more d-flex align-items-end justify-content-center">
                        {edge?.link?.title}
                      </ReadMore>
                    </InnerPackageWrapper>
                  </div>
                </Package>
              </PackageWrapper>
            )
          })}
        </PackagesWrapper>
      </PackageContainer>
    </section>
  )
}

export default Packages
